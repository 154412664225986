
import { defineComponent,onMounted} from "vue";
import { useStore } from "vuex";
import ListPolicy from "@/components/partials/policy/List-Policy.vue";
import { MenuComponent } from "@/assets/ts/components";
import { setCurrentPageBreadcrumbs } from "@/core/helpers/breadcrumb";
import MasterService from "@/core/services/car/MasterService";
import { roles } from "@/store/stateless/store";

export default defineComponent({
  name: "policy-assigned",
  components: {
    ListPolicy,

    //   AIExplore
  },
  data() {
    return {

      status: 2,
      type: 'policy_assigned'

    };
  },
  setup() {
    const store = useStore();
      onMounted(() => {
      if(!roles.policiesAssigned.includes(roles.userId())) window.location.href = '/#/404'
      
      
      MenuComponent.reinitialization();

      setCurrentPageBreadcrumbs("Policy Assigned", ["Policy"]);
      MasterService.getDocumentTypes({
        categories: 'standard'
      })

    });
    return {
      roles
    };
  },
});
